<template>
  <div class="box">
    <div class="login">
      <div class="login-header">
        <div>鑫易付商户系统</div>
        <div>welcome to the koala cloud pay business system</div>
      </div>
      <div class="login-main">
        <el-form :model="form" ref="ruleFormRef"  :rules="rules" status-icon >
          <el-form-item>
            <el-input type="text" v-model="form.phone" placeholder="请输入账号" :autofocus="true" @blur="queryMerchantsNO(form.phone)" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="form.password" show-password :auto-insert-space="true" placeholder="请输入密码" @keyup.enter="hanldeCheck" />
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.no" placeholder="请选择商户" style="width: 100%;">
              <el-option  v-for="(item,index) in option" :key="index"  :label="item.merchant_name" :value="item.no" />
            </el-select>
          </el-form-item>
          <el-from-item>
            <el-button color="#fff" :dark="true" size="large" type="primary" round  style="width: 100%;" @click="hanldeCheck" v-loading="isLoading">{{loginText}}</el-button>
          </el-from-item>
        </el-form>
        <vcode :show="codeShow" @success="codeSuccess" @close="codeClose"></vcode>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import vcode from "@/components/CustomValidateCode/index.vue"
export default {
  components: {
    vcode,
  },
  data() {
    return {
      form: {
        phone: '',
        password: '',
        no:''
      },
      option:[],
      loginText: '登 录',
      isLoading: false,
      codeShow:false,
      err:{
        count:0
      },
      rules:{
        password:[
          { required: true, message: '请输入密码', trigger: 'blur'},
        ]
      }
    }
  },
  methods: {
    async onSubmit() {
      const r = await this.$api.login({ phone: this.form.phone, password: this.form.password ,no:this.form.no})
      if (r.status == 200 && r.success) {
        const token = {
          access_token: r.data.access_token,
          expires_in: r.data.expires_in,
          token_type: r.data.token_type,
          begin_time: new Date().getTime()
        }
        sessionStorage.setItem('token', JSON.stringify(token))
        sessionStorage.setItem('agent_user', JSON.stringify(r.data.user))
        this.$router.push({ path: '/dashboard' })
      } else {
        ElMessage({ message: `${r.error.message}`,type: 'warning',})
        this.isLoading = false
        this.loginText = '登 录'
      }
    },
    async queryMerchantsNO(phone){
      if(!(/^1[3456789]\d{9}$/.test(phone))){ 
        ElMessage({ message: `请输入正确的手机号`,type: 'warning',})
        return false; 
      }else{
        this.$api.queryno({phone:phone}).then(res=>{
        if(res.status == 200 && res.success){
          this.option=res.data
        }else{
          ElMessage({ message: `${res.error.message}`,type: 'warning',})
        }
      })
      }
    },
    // 触发校验
    async hanldeCheck(){
      this.$refs.ruleFormRef.validate((valid)=>{
      this.loginText = '登 录 中...'
      this.isLoading = true
        if (valid) {
          this.codeShow=true
        }else{
          this.isLoading = false
          this.loginText = '登 录'
          return false;
        }
      })
    },
    codeSuccess(msg) {
      console.log(`总共耗时:${msg.toFixed(2)} 🕙`);
      this.codeShow = false;
      this.onSubmit()
    },
    codeClose() {
      this.codeShow = false;
      this.isLoading = false
      this.loginText = '登 录'
    },
  },
}
</script>



<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom left, #2d3e69, #4d6fbf);
  overflow: hidden;

  .login {
    width: 400px;
    // height: 300px;
    position: absolute;
    top: 48%;
    left: 50%;
    text-align: center;
    margin-top: -150px;
    margin-left: -200px;
    background-image: linear-gradient(to top, #4d6fbf, #2d3e69);
    border-radius: 15px;

    @width: 250px;

    .login-header {
      width: 100%;
      height: 80px;
      line-height: 80px;
      padding-top: 15px;
      color: #fff;

      > div {
        width: @width;
        height: 20px;
        line-height: 20px;
        margin: 0 auto;
        font-size: 14px;
        text-align: center;
      }

      > div:first-child {
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        text-align: center;
      }
    }

    .login-main {
      // width: @width;
      width: 80%;
      margin: 0 auto;
      margin-top: 20px;
      padding-bottom: 20px;

      .el-button--primary {
        background: #6b8ec6 !important;
        border-color:#6b8ec6 !important;
        color: #fff !important;

        .el-loading-mask {
          background-color: transparent !important;
        }
      }
    }
  }
}
</style>